/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type OpenAICompletionContentType = typeof OpenAICompletionContentType[keyof typeof OpenAICompletionContentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpenAICompletionContentType = {
  benefits_list: 'benefits_list',
  company_summary: 'company_summary',
  role_summary: 'role_summary',
  company_values_list: 'company_values_list',
  availability: 'availability',
} as const;

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type EventActivityAnalyticsActivityType = typeof EventActivityAnalyticsActivityType[keyof typeof EventActivityAnalyticsActivityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventActivityAnalyticsActivityType = {
  created_scheduling_request: 'created_scheduling_request',
  assigned_scheduling_request_owner: 'assigned_scheduling_request_owner',
  canceled_scheduling_request: 'canceled_scheduling_request',
  archived_scheduling_request: 'archived_scheduling_request',
  re_requested_availability: 're_requested_availability',
  candidate_submited_availability: 'candidate_submited_availability',
  candidate_scheduled_interview: 'candidate_scheduled_interview',
  canceled_scheduling_interview_request: 'canceled_scheduling_interview_request',
  confirmed_scheduling_interview_request: 'confirmed_scheduling_interview_request',
  sent_calendar_invites: 'sent_calendar_invites',
  sent_confirmed_calendar_invites: 'sent_confirmed_calendar_invites',
  sent_custom_email: 'sent_custom_email',
  rescheduled: 'rescheduled',
  interviewer_auto_replaced: 'interviewer_auto_replaced',
  no_interviewer_for_auto_replacement: 'no_interviewer_for_auto_replacement',
  candidate_declined: 'candidate_declined',
  interviewer_declined: 'interviewer_declined',
  interviewer_accepted: 'interviewer_accepted',
  candidate_accepted: 'candidate_accepted',
  candidate_reminder_sent: 'candidate_reminder_sent',
  added_attendee_via_calendar: 'added_attendee_via_calendar',
  removed_attendee_via_calendar: 'removed_attendee_via_calendar',
  added_room_via_calendar: 'added_room_via_calendar',
  removed_room_via_calendar: 'removed_room_via_calendar',
  auto_added_trainee: 'auto_added_trainee',
  auto_replaced_trainee: 'auto_replaced_trainee',
} as const;

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type InterviewTemplateDuration = typeof InterviewTemplateDuration[keyof typeof InterviewTemplateDuration];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewTemplateDuration = {
  NUMBER_15: 15,
  NUMBER_20: 20,
  NUMBER_30: 30,
  NUMBER_40: 40,
  NUMBER_45: 45,
  NUMBER_50: 50,
  NUMBER_60: 60,
  NUMBER_75: 75,
  NUMBER_90: 90,
  null: null,
} as const;
